import shortid from "shortid";
import { value } from "../helpers";
class NfSaidaItem {
  constructor(item, index, origensMercadoriaList, unidadeList) {
    this.id = item.id ? item.id : shortid.generate();
    this.ordem = index + 1;
    this.produtoId = item?.produtoId;
    this.unidade = item?.unidade ?? "";
    this.quantidade = parseInt(item.quantidade);
    this.saldoExportar = parseInt(item.saldoExportar);
    this.grupoTributacaoId = item.grupoTributacaoId;
    this.modeloTributacaoId = item.modeloTributacaoId;
    this.codigo = item.codigo;
    this.cean = item.cean;
    this.codigoBarra = item.codigoBarra;
    this.descricao = item.descricao;
    this.desconto = item.desconto;
    this.despesas = item.despesas;
    this.acrescimo = item.acrescimo;
    this.tributosIcmsOrigem =
      value.autoComplete(origensMercadoriaList, item?.origemMercadoriaId)
        .codigo || item.tributosIcmsOrigem;
    this.unidade = unidadeList.find(
      (unidade) =>
        unidade.codigo === item.unidade ||
        unidade.id === item.unidadeComercialVendaId
    )?.codigo;
    this.ncm = item.ncmCodigo || item.ncm;
    this.informacaoAdicional = item.informacaoAdicionalNfe;
  }

  setDescricao(descricao) {
    this.descricao = descricao;
  }

  setValor(valor) {
    this.valor = parseFloat(valor);
  }

  setSubtotal(quantidade) {
    this.subtotal = this.valor * parseInt(quantidade);
  }

  setImposto(item) {
    this.tributosIcmsAliquota = item.tributosIcmsAliquota;
    this.tributosIpiAliquota = item.tributosIpiAliquota;
    this.aliquotaMva = item.aliquotaMva;
    this.impostoIcmsStAliquota = item.impostoIcmsStAliquota;
    //this.tributosIcmsValor = nfSaidaTotalTributos.calculaValorIcms(item, {});
  }
}

export default NfSaidaItem;
