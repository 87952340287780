import { addDays, addMonths } from "date-fns";

export function parcelaData(indice, data, intervaloDias) {
  const dias = indice * (intervaloDias ?? 1);
  const meses = Math.floor(dias / 30);
  const diasRestantes = dias % 30;
  const dataAtualizada = addMonths(new Date(data), meses);
  const dataFinal = addDays(dataAtualizada, diasRestantes);
  return dataFinal;
}
