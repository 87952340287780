import {
  Dialog,
  DialogContent,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Grid,
  Chip,
} from "../../../../components";
import shortid from "shortid";

const AtalhosDialog = ({ open, setOpen, atalhos }) => {
  return (
    <Dialog
      disableBackdropClick
      fullWidth
      maxWidth="xl"
      PaperComponent="div"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Card className="p-3">
          <CardHeader title="Atalhos" />
          <CardContent className="p-3">
            <Grid container>
              <Grid item xs={4} className="mb-2 d-flex flex-column">
                {atalhos.map((ajuda, index) => {
                  if (index < 8) {
                    return (
                      <Grid item key={shortid.generate()}>
                        <span key={shortid.generate()}>
                          <Chip
                            key={shortid.generate()}
                            label={ajuda.atalho}
                            variant="default"
                            className="m-1 px-2"
                          />
                        </span>
                        <span key={shortid.generate()}>{ajuda.nome}</span>
                        <hr key={shortid.generate()} />
                      </Grid>
                    );
                  }
                  return null;
                })}
              </Grid>
              <Grid item xs={4} className="mb-2 d-flex flex-column">
                {atalhos.map((ajuda, index) => {
                  if (index >= 8 && index < 14) {
                    return (
                      <Grid item key={shortid.generate()}>
                        <span key={shortid.generate()}>
                          <Chip
                            key={shortid.generate()}
                            label={ajuda.atalho}
                            variant="default"
                            className="m-1 px-2"
                          />
                        </span>
                        <span key={shortid.generate()}>{ajuda.nome}</span>
                        <hr key={shortid.generate()} />
                      </Grid>
                    );
                  }
                  return null;
                })}
              </Grid>
              <Grid item xs={4} className="mb-2 d-flex flex-column">
                {atalhos.map((ajuda, index) => {
                  if (index >= 15 && index < 20) {
                    return (
                      <Grid item key={shortid.generate()}>
                        <span key={shortid.generate()}>
                          <Chip
                            key={shortid.generate()}
                            label={ajuda.atalho}
                            variant="default"
                            className="m-1 px-2"
                          />
                        </span>
                        <span key={shortid.generate()}>{ajuda.nome}</span>
                        <hr key={shortid.generate()} />
                      </Grid>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              color="inherit"
              variant="contained"
              onClick={() => setOpen(false)}
            >
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              />
              Fechar
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default AtalhosDialog;
