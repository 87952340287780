import { history } from "../services";

const voltar = () => {
  const url = window.location.pathname;
  const temNumero = /\/\d+/;
  const novaUrl =
    temNumero.test(url) && !url.includes("empresas")
      ? url.replace(temNumero, "")
      : url.endsWith("/create")
      ? url.slice(0, -7)
      : "/dashboard";
  history.push(novaUrl);
};

export default voltar;
