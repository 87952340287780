import { useEffect } from "react";
import { history, MeuUsuarioService, SessionService } from "../../services";
import { StorageHelper } from "../../helpers";
import "./loading-pdv.style.scss";

const LoadingPdvView = () => {
  const meuUsuarioService = new MeuUsuarioService();
  const sessionService = new SessionService();
  const storageHelper = new StorageHelper();
  const images = {
    logo: require("../../assets/images/icon-lite.svg"),
  };

  useEffect(() => {
    verificaToken();
  }, []);

  const verificaToken = () => {
    const token = storageHelper.getLocal("token");
    if (!token) {
      return history.push("/login");
    }
    meuUsuarioService.validarToken().then((result) => {
      if (!result.isAxiosError) {
        return history.push("/pdv");
      }
      return sessionService.deslogar();
    });
  };

  return (
    <div className="inicio-container">
      <img src={images.logo.default} className="inicio-container-img" />
    </div>
  );
};

export default LoadingPdvView;
