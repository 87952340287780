import { totalItensLista } from "./";

const calcularTributos = {
  calculaBaseIcms: (produto, nfSaida) => {
    if (produto?.tributosIcmsBaseCalculoValor) {
      return produto?.tributosIcmsBaseCalculoValor;
    }
    if (
      (produto?.calculaIcms && produto?.tributosIcmsCsosn === "900") ||
      nfSaida?.documentoDevolucaoFornecedor
    ) {
      if (produto?.tributosIcmsBaseCalculoPercentualReducao) {
        return (
          produto?.subtotal -
          produto?.subtotal * produto?.tributosIcmsBaseCalculoPercentualReducao
        );
      } else {
        return produto?.subtotal;
      }
    } else {
      return 0;
    }
  },
  calculaBaseIcmsSt: (produto, nfSaida) => {
    if (produto?.tributosIcmsBaseCalculoModalidadeST) {
      return produto.tributosIcmsBaseCalculoModalidadeST;
    }
    if (
      (produto?.calculaIcmsSt && produto?.tributosIcmsCsosn === "900") ||
      nfSaida?.documentoDevolucaoFornecedor
    ) {
      const subtotalComIpi =
        produto?.subtotal +
        (produto?.subtotal * (produto?.tributosIpiAliquota || 0)) / 100;
      const total = parseFloat(
        (subtotalComIpi + (subtotalComIpi * produto.aliquotaMva) / 100).toFixed(
          2
        )
      );
      if (produto?.impostoIcmsStReducao) {
        return total - (total * produto?.impostoIcmsStReducao) / 100;
      } else {
        return total;
      }
    } else {
      return 0;
    }
  },
  calculaBaseIpi: (produto, nfSaida) => {
    if (produto?.tributosIpiBaseCalculoValor) {
      return produto?.tributosIpiBaseCalculoValor;
    }
    if (
      (produto?.calculaIcms && produto?.tributosIcmsCsosn === "900") ||
      nfSaida?.documentoDevolucaoFornecedor
    ) {
      return produto?.subtotal;
    } else {
      return "0";
    }
  },
  calculaValorIcms: (produto, nfSaida) => {
    if (produto?.tributosIcmsValor && nfSaida.id) {
      return produto?.tributosIcmsValor;
    }
    if (
      (produto?.calculaIcms && produto?.tributosIcmsCsosn === "900") ||
      nfSaida?.documentoDevolucaoFornecedor
    ) {
      if (produto?.tributosIcmsBaseCalculoPercentualReducao) {
        return (
          ((produto?.subtotal -
            (produto?.subtotal *
              produto?.tributosIcmsBaseCalculoPercentualReducao) /
              100) *
            (produto?.tributosIcmsAliquota || 0)) /
          100
        );
      } else {
        return (produto?.subtotal * (produto?.tributosIcmsAliquota || 0)) / 100;
      }
    } else {
      return 0;
    }
  },
  calculaValorIcmsSt: (produto, nfSaida) => {
    if (produto?.impostoIcmsSt && nfSaida.id) {
      return produto.impostoIcmsSt;
    }
    if (
      (produto?.calculaIcmsSt && produto?.tributosIcmsCsosn === "900") ||
      nfSaida?.documentoDevolucaoFornecedor
    ) {
      const subtotalComIpi =
        produto?.subtotal +
        (produto?.subtotal * (produto?.tributosIpiAliquota || 0)) / 100;
      const total = parseFloat(
        (subtotalComIpi + (subtotalComIpi * produto.aliquotaMva) / 100).toFixed(
          2
        )
      );
      if (produto?.impostoIcmsStReducao) {
        return (
          total -
          ((total * produto.impostoIcmsStReducao) / 100) *
            ((produto?.impostoIcmsStAliquota || 0) / 100) -
          calcularTributos.calculaValorIcms(produto, nfSaida)
        );
      } else {
        return (
          (total * (produto?.impostoIcmsStAliquota || 0)) / 100 -
          calcularTributos.calculaValorIcms(produto, nfSaida)
        );
      }
    } else {
      return 0;
    }
  },
  calculaValorIpi: (produto, nfSaida) => {
    if (produto?.tributosIpiValor && nfSaida.id) {
      return produto?.tributosIpiValor;
    }
    if (
      (produto?.calculaIpi && produto?.tributosIpiCsosn === "900") ||
      nfSaida?.documentoDevolucaoFornecedor
    ) {
      return (produto?.subtotal * (produto?.tributosIpiAliquota || 0)) / 100;
    } else {
      return "0";
    }
  },
  totalBaseIcms: (id, nfSaidaItens, nfSaida) => {
    if (id) {
      return nfSaidaItens.reduce((acumulador, itemAtual) => {
        return acumulador + itemAtual.tributosIcmsBaseCalculoValor;
      }, 0);
    } else if (nfSaida?.documentoDevolucaoFornecedor) {
      return totalItensLista(nfSaidaItens);
    } else {
      return nfSaidaItens.reduce((acumulador, itemAtual) => {
        const subTotal = itemAtual?.valor * (itemAtual?.quantidade ?? 1);
        if (itemAtual?.tributosIcmsCsosn) {
          if (
            itemAtual.tributosIcmsCsosn.includes("900") &&
            itemAtual?.calculaIcms
          ) {
            const baseCalculo =
              subTotal -
              (subTotal * itemAtual.tributosIcmsBaseCalculoPercentualReducao ||
                0);
            return acumulador + parseFloat(baseCalculo);
          }
        }
        return acumulador;
      }, 0);
    }
  },
  totalValorIcms: (id, nfSaidaItens, nfSaida) => {
    if (id) {
      return nfSaidaItens.reduce((acumulador, itemAtual) => {
        return acumulador + itemAtual.tributosIcmsValor;
      }, 0);
    } else if (nfSaida?.documentoDevolucaoFornecedor) {
      return nfSaidaItens.reduce((acumulador, itemAtual) => {
        const subTotal = itemAtual?.valor * (itemAtual?.quantidade ?? 1);
        const baseCalculo =
          subTotal -
          (subTotal * itemAtual?.tributosIcmsBaseCalculoPercentualReducao || 0);
        const valorIcms =
          baseCalculo * ((itemAtual?.tributosIcmsAliquota ?? 0) / 100);
        return acumulador + parseFloat(valorIcms);
      }, 0);
    } else {
      return nfSaidaItens.reduce((acumulador, itemAtual) => {
        const subTotal = itemAtual?.valor * (itemAtual?.quantidade ?? 1);
        if (itemAtual?.tributosIcmsCsosn) {
          if (
            itemAtual.tributosIcmsCsosn.includes("900") &&
            itemAtual?.calculaIcms
          ) {
            const baseCalculo =
              subTotal -
              (subTotal * itemAtual.tributosIcmsBaseCalculoPercentualReducao ||
                0);
            const valorIcms =
              baseCalculo * ((itemAtual?.tributosIcmsAliquota ?? 0) / 100);
            return acumulador + parseFloat(valorIcms);
          }
        }
        return acumulador;
      }, 0);
    }
  },
  totalBaseIcmsSt: (id, nfSaidaItens, nfSaida) => {
    if (id) {
      return nfSaidaItens.reduce((acumulador, itemAtual) => {
        return acumulador + itemAtual.impostoIcmsStBase;
      }, 0);
    } else if (nfSaida?.documentoDevolucaoFornecedor) {
      return nfSaidaItens.reduce((acumulador, itemAtual) => {
        return (
          acumulador +
          calcularTributos.calculaBaseIcmsSt(itemAtual, nfSaida)
        );
      }, 0);
    } else {
      return nfSaidaItens.reduce((acumulador, itemAtual) => {
        const subTotal = itemAtual?.valor * (itemAtual?.quantidade ?? 1);
        if (itemAtual?.tributosIcmsCsosn) {
          if (
            itemAtual.tributosIcmsCsosn.includes("900") &&
            itemAtual?.calculaIcmsSt
          ) {
            const baseCalculo =
              subTotal - (subTotal * itemAtual.impostoIcmsStAliquota || 0);
            return acumulador + parseFloat(baseCalculo);
          }
        }
        return acumulador;
      }, 0);
    }
  },
  totalValorIcmsSt: (id, nfSaidaItens, nfSaida) => {
    if (id) {
      return nfSaidaItens.reduce((acumulador, itemAtual) => {
        return acumulador + itemAtual.impostoIcmsSt;
      }, 0);
    } else if (nfSaida?.documentoDevolucaoFornecedor) {
      return nfSaidaItens.reduce((acumulador, itemAtual) => {
        return (
          acumulador +
          calcularTributos.calculaValorIcmsSt(itemAtual, nfSaida)
        );
      }, 0);
    } else {
      return nfSaidaItens.reduce((acumulador, itemAtual) => {
        const subTotal = itemAtual?.valor * (itemAtual?.quantidade ?? 1);
        if (itemAtual?.tributosIcmsCsosn) {
          if (
            itemAtual.tributosIcmsCsosn.includes("900") &&
            itemAtual?.calculaIcmsSt
          ) {
            const baseCalculo =
              subTotal - (subTotal * itemAtual.impostoIcmsStReducao || 0);
            const valorIcms =
              baseCalculo * ((itemAtual?.impostoIcmsStAliquota ?? 0) / 100);
            return acumulador + parseFloat(valorIcms);
          }
        }
        return acumulador;
      }, 0);
    }
  },
  totalValorIpi: (id, nfSaidaItens) => {
    if (id) {
      return nfSaidaItens.reduce((acumulador, itemAtual) => {
        return acumulador + itemAtual.tributosIpiValor;
      }, 0);
    } else {
      return nfSaidaItens.reduce((acumulador, itemAtual) => {
        const subTotal = itemAtual?.valor * (itemAtual?.quantidade ?? 1);
        const valorIpi = subTotal * (itemAtual?.tributosIpiAliquota / 100 ?? 0);
        return acumulador + parseFloat(valorIpi);
      }, 0);
    }
  },
};

export default calcularTributos;
