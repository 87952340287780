import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
} from "../../../../../components";
import AdicionarPagamentoDialogContent from "./adicionar-pagamento-dialog-content.component";
import { CardHeader } from "@mui/material";
import shortid from "shortid";
import {
  calculaFloor,
  parcelaData,
  totalPagamentos,
  totalVenda,
} from "../../../../../utils";
import { notification } from "../../../../../services";

const AdicionarPagamentoDialog = ({
  open,
  setOpen,
  pagamentoSelecionado,
  setPagamentoSelecionado,
  meioPagamentoList,
  condicaoPagamentoList,
  condicaoSelecionada,
  setCondicaoSelecionada,
  listaPagamentosVenda,
  setListaPagamentosVenda,
  venda,
  listaProdutosVenda,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [condicoesFiltradas, setCondicoesFiltradas] = useState([]);
  const [valorPagamento, setValorPagamento] = useState(0);
  const [numeroAutorizacao, setNumeroAutorizacao] = useState(null);
  const [inputError, setInputError] = useState(null);

  useEffect(() => {
    if (open) {
      if (venda?.totalVenda !== venda?.totalPagamentos) {
        setValorPagamento(
          parseFloat(venda?.totalVenda ?? 0) -
            parseFloat(venda?.totalPagamentos ?? 0)
        );
      }
      if (pagamentoSelecionado) {
        const condicoes = filtrarCondicoesPagamento(pagamentoSelecionado);
        setCondicoesFiltradas(condicoes);
        setActiveStep(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const filtrarCondicoesPagamento = (pagamentoSelecionado) => {
    const condicaoPagamentoFiltrado = [];
    condicaoPagamentoList.forEach((condicao) =>
      // eslint-disable-next-line array-callback-return
      condicao.meiosPagamentos.filter((meio) => {
        if (meio.id === pagamentoSelecionado?.id) {
          condicaoPagamentoFiltrado.push(condicao);
        }
      })
    );
    if (condicaoPagamentoFiltrado.length > 0) {
      return condicaoPagamentoFiltrado;
    } else {
      return [];
    }
  };

  const handleCloseDialog = () => {
    handleInitPagamentos();
    setOpen(false);
  };

  const handleInitPagamentos = () => {
    setActiveStep(0);
    setPagamentoSelecionado(null);
    setCondicaoSelecionada(null);
    setCondicoesFiltradas([]);
  };

  const onSubmitParcelas = (event) => {
    event.preventDefault();
    handleGerarParcelas();
  };

  function validarParcela() {
    if (!pagamentoSelecionado?.id) {
      notification.erroValidacao("Meio de pagamento");
      return true;
    }
    if (!condicaoSelecionada?.id) {
      notification.erroValidacao("Condição de pagamento");
      return true;
    }
    if (!condicaoSelecionada?.parcelas) {
      notification.erroValidacao("Quantidade de Parcelas");
      return true;
    }
    if (!valorPagamento) {
      setInputError("valorPagamentoPdv");
      notification.erroValidacao("Valor do título");
      return true;
    }
    if (pagamentoSelecionado?.cartaoCreditoDebito && !numeroAutorizacao) {
      setInputError("numeroAutorizacao");
      notification.erroValidacao("Número de Autorização");
      return true;
    }
    if (
      parseFloat(totalPagamentos(listaPagamentosVenda) || 0) +
        parseFloat(valorPagamento) >
      totalVenda(venda, listaProdutosVenda)
    ) {
      if (pagamentoSelecionado?.nfePagamento === "fpDinheiro") return;
      notification.alertaGenericos(
        "Valor inserido ultrapassa total da Nota fiscal"
      );
      return true;
    }
    return false;
  }

  const handleGerarParcelas = () => {
    const parcelaIsValid = validarParcela();
    if (parcelaIsValid) return;
    const valorParcela = valorPagamento / condicaoSelecionada.parcelas;
    const valorFormatado = calculaFloor(valorParcela, 2);
    const restoDivisao =
      valorPagamento - valorFormatado * condicaoSelecionada.parcelas;
    for (
      let contador = 1;
      contador <= condicaoSelecionada?.parcelas;
      contador++
    ) {
      const valorTitulo =
        contador === condicaoSelecionada.parcelas
          ? valorFormatado + parseFloat(restoDivisao.toFixed(2))
          : valorFormatado;
      const parcelaGerada = {
        id: shortid.generate(),
        numeroTitulo: contador,
        condicaoPagamentoId: condicaoSelecionada?.id,
        numeroAutorizacao,
        meioPagamentoId: pagamentoSelecionado?.id,
        meioPagamentoSelecionado: pagamentoSelecionado,
        valorTitulo,
        dataVencimento: parcelaData(
          contador,
          new Date(),
          condicaoSelecionada?.periodo
        ),
      };
      setListaPagamentosVenda((prevState) => [...prevState, parcelaGerada]);
    }
    setValorPagamento(
      venda?.totalVenda -
        parseFloat(venda?.totalPagamentos ?? 0) -
        parseFloat(valorPagamento ?? 0)
    );
    handleCloseDialog();
    notification.sucessoGenericos("Parcelas geradas com sucesso");
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperComponent="div"
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Card className="p-3">
          <CardHeader
            title={
              <Grid container alignItems="center">
                {activeStep === 1 && (
                  <Button
                    variant="text"
                    color="padrao"
                    onClick={handleInitPagamentos}
                  >
                    <i
                      className="ph-fill ph-arrow-left"
                      style={{ color: "#494C62", fontSize: 23 }}
                    ></i>
                  </Button>
                )}
                ADICIONAR PAGAMENTO
              </Grid>
            }
          />
          <CardContent className="p-3">
            <AdicionarPagamentoDialogContent
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              meioPagamentoList={meioPagamentoList}
              condicaoPagamentoList={condicaoPagamentoList}
              pagamentoSelecionado={pagamentoSelecionado}
              setPagamentoSelecionado={setPagamentoSelecionado}
              condicoesFiltradas={condicoesFiltradas}
              setCondicoesFiltradas={setCondicoesFiltradas}
              condicaoSelecionada={condicaoSelecionada}
              setCondicaoSelecionada={setCondicaoSelecionada}
              filtrarCondicoesPagamento={filtrarCondicoesPagamento}
              valorPagamento={valorPagamento}
              setValorPagamento={setValorPagamento}
              numeroAutorizacao={numeroAutorizacao}
              setNumeroAutorizacao={setNumeroAutorizacao}
              venda={venda}
              onSubmitParcelas={onSubmitParcelas}
              inputError={inputError}
            />
          </CardContent>
          <CardActions>
            {activeStep === 1 && (
              <Button
                color="primary"
                variant="contained"
                onClick={onSubmitParcelas}
              >
                <i
                  className="ph-fill ph-check-circle"
                  style={{ fontSize: 22, marginRight: 10, color: "white" }}
                />
                Confirmar
              </Button>
            )}
            <Button
              color="inherit"
              variant="contained"
              onClick={handleCloseDialog}
            >
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              />
              Fechar
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default AdicionarPagamentoDialog;
