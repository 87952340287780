import React, { useEffect, useState } from "react";
import {
  Loading,
  MarcaEmpresa,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "../../components";
import { history, LoginService, notification } from "../../services";
import { StorageHelper, value, InputErros } from "../../helpers";
import { responseErros } from "../../utils";
import { loginValidator } from "./middlewares";
import ReCAPTCHA from "react-google-recaptcha";
import store from "../../config/store";
import { RecuperarSenhaDialogo } from "./components";
import "./login-pdv-style.scss";

const LoginPdvView = () => {
  const [login, setLogin] = useState({});
  const [loading, setLoading] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const [tempoBloqueio, setTempoBloqueio] = useState("5:00");
  const [usuarioBloqueado, setUsuarioBloqueado] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [tokenRecaptcha, setTokenRecaptcha] = useState(null);
  const [erro, setErro] = useState(false);
  const [abrirDialogoRecuperarSenha, setAbrirDialogoRecuperarSenha] =
    useState(false);
  const recaptchaRef = React.createRef();
  const inputErros = new InputErros(inputErro, setInputErro);
  const loginService = new LoginService();
  const storageHelper = new StorageHelper();
  const images = { logo: require("../../assets/images/icon-lite-white.svg") };

  useEffect(() => {
    verificaToken();
  }, []);

  const verificaToken = () => {
    const token = storageHelper.getLocal("token");
    if (!token) {
      return history.push("/login");
    }
  };

  const onSubmitLogar = (event) => {
    event.preventDefault();
    setErro(false);
    if (!tokenRecaptcha && store.enviroment == "production") {
      return notification.alertaApiFecharAuto("Resolva o reCAPTCHA");
    }
    loginValidator
      .validate(login, { abortEarly: false })
      .then(logar())
      .catch((err) => inputErros.set(err));
  };

  const logar = async () => {
    setLoading(true);
    const res = await loginService.logar(
      login,
      store.enviroment == "production" ? tokenRecaptcha : "teste"
    );
    setLoading(false);
    if (!res.isAxiosError) {
      storageHelper.setLocal("token", res.data.token);
      storageHelper.setLocal("dados", JSON.stringify(res.data.dados));
      history.push("/pdv");
    } else {
      if (res.response && res.response.status == 400) {
        setErro(true);
        notification.alertaApiFecharAuto(res.response.data.message);
        if (
          res.response.data.message ==
          "Este usuário está bloqueado temporariamente"
        ) {
          bloquearUsuario();
        }
      } else {
        responseErros(res);
      }
      window.grecaptcha.reset();
      setTokenRecaptcha(null);
    }
  };

  const onChangeInput = (event) => {
    setLogin((prevState) => ({
      ...prevState,
      [event.target.name]: String(event.target.value).trim(),
    }));
  };

  function bloquearUsuario() {
    setUsuarioBloqueado(true);
    let minutos = 4;
    let segundos = 60;
    const interval = setInterval(() => {
      segundos--;
      if (segundos == 0) {
        if (minutos == 0) {
          setTempoBloqueio("5:00");
          setUsuarioBloqueado(false);
          clearInterval(interval);
          return;
        }
        segundos = 60;
        minutos--;
      }
      const segundosFormatado = segundos < 10 ? `0${segundos}` : segundos;
      setTempoBloqueio(`${minutos}:${segundosFormatado}`);
    }, 1000);
  }

  function onChangeRecaptcha(value) {
    setTokenRecaptcha(value);
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onClickAbrirDialogoRecuperarSenha = (e) => {
    e.preventDefault();
    setAbrirDialogoRecuperarSenha(true);
  };

  return (
    <Grid
      container
      className="bg-login d-flex justify-content-center align-items-center"
    >
      <Grid item xs={2} className="bloco-logo">
        <div className="logo">
          <img src={images.logo.default} width={"35px"} />
        </div>
        <MarcaEmpresa className="logo" />
      </Grid>
      <Grid container>
        <Grid item xs={6} className="d-flex justify-content-end">
          <Grid item xs={9}>
            <p className="ss-principal">
              Bem-Vindo <br /> ao&nbsp;
              <span style={{ color: "white", fontWeight: 900 }}>SIAF LITE</span>
            </p>
            <p className="ss-secundario">
              Somos mais do que uma empresa de gestão empresarial. Nosso
              objetivo é criar soluções seguras, intuitivas e eficientes para
              facilitar o gerenciamento das empresas com qualidade.
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="login">
        <Grid item xs={9}>
          {usuarioBloqueado && (
            <span className="text-danger">
              Usuário bloqueado por {tempoBloqueio}
            </span>
          )}
          <form onSubmit={onSubmitLogar} className="mt-2">
            <label className="txt-color-purple">Login</label>
            <TextField
              variant="outlined"
              id="email"
              name="email"
              label="E-mail"
              type="text"
              margin="normal"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => onChangeInput(event)}
              value={value.text(login.email)}
              error={inputErros.get("email") || erro ? true : false}
            />
            <TextField
              variant="outlined"
              label="Senha"
              name="senha"
              id="senha"
              margin="normal"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
              type={showPassword ? "text" : "password"}
              onChange={(event) => onChangeInput(event)}
              value={value.text(login.senha)}
              error={inputErros.get("senha") || erro ? true : false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <i
                          className="ph-fill ph-eye"
                          style={{ color: "#494C62", fontSize: 18 }}
                        ></i>
                      ) : (
                        <i
                          className="ph-fill ph-eye-slash"
                          style={{ color: "#494C62", fontSize: 18 }}
                        ></i>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography className="m-2">
              <a
                style={{ textDecoration: "none" }}
                href="#"
                onClick={(e) => onClickAbrirDialogoRecuperarSenha(e)}
              >
                Esqueci minha senha
              </a>
            </Typography>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={store.keyRecaptcha}
              onChange={onChangeRecaptcha}
            />
            <Button
              fullWidth
              variant="contained"
              disabled={usuarioBloqueado}
              size="large"
              type="submit"
              className="mt-3"
              color={usuarioBloqueado ? "padrao" : "primary"}
              disableElevation
            >
              Entrar
            </Button>
          </form>
        </Grid>
      </Grid>
      <RecuperarSenhaDialogo
        abrirDialogoRecuperarSenha={abrirDialogoRecuperarSenha}
        setAbrirDialogoRecuperarSenha={setAbrirDialogoRecuperarSenha}
        setLoading={setLoading}
      />
      <Loading loading={loading} />
    </Grid>
  );
};

export default LoginPdvView;
