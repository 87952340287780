import { Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import lightTheme from "./styles/light-theme";
import GlobalStyles from "./styles/global";
import { PdvView, LoginPdvView, LoadingPdvView } from "./views";

const Routes = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles themeMode={lightTheme} />
      <Switch>
        <Route
          render={(matchProps) => <PdvView {...matchProps}></PdvView>}
          path="/pdv"
        />
        <Route
          render={(matchProps) => <LoginPdvView {...matchProps}></LoginPdvView>}
          path="/login"
        />
        <Route
          render={(matchProps) => (
            <LoadingPdvView {...matchProps}></LoadingPdvView>
          )}
          path="/"
        />
        {/* <Route
          render={(matchProps) => <NotFoundView {...matchProps}></NotFoundView>}
        /> */}
      </Switch>
    </ThemeProvider>
  );
};

export default Routes;
