import qrcode from "qrcode-generator";
import { impressoraModule, notification } from "../../services";
import store from "../../config/store";

export const getListaPagamentoPadrao = (configuracao, meioPagamentoList) => {
  const idsPagamentos =
    configuracao?.meioPagamentosPadroesConfiguracoes?.map((pagamento) => ({
      id: pagamento.meioPagamentoId,
    })) || [];
  return meioPagamentoList.filter((item) =>
    idsPagamentos.some((idPagamento) => item.id === idPagamento.id)
  );
};

export const getAspectRatioLogoEmpresa = () => {
  const img = document.getElementById("logo-empresa");
  const aspectRatio = (img?.naturalWidth || 1) / (img?.naturalHeight || 1);
  return aspectRatio <= 1.5 ? 100 : 20;
};

export const getQRCodeNfce = (qrCode) => {
  const qr = qrcode(0, "L");
  qr.addData(qrCode || "");
  qr.make();
  return qr.createDataURL(4);
};

export const impressaoNfce = async (empresa, dados, impressaoFiscal) => {
  if (Object.keys(empresa)?.length && Object?.keys(dados).length) {
    const body = {
      ...empresa,
      ...dados,
      qrCodeImage: getQRCodeNfce(dados?.qrCode),
      aspectRatio: getAspectRatioLogoEmpresa(),
    };
    impressoraModule.impressora(body, store.localInstalacao, impressaoFiscal);
  } else {
    notification.erroGenericos("Não há dados a serem impressos");
  }
};
