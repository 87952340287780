import { formatDate } from "../utils";

export class NfSaida {
  pedidoIds = null;
  orcamentoIds = null;
  serieDocumento = null;
  dataEmissao = null;
  operacaoFiscalId = null;
  grupoTributarios = null;
  modeloDocumento = null;
  notasCredito = null;
  operacaoFiscal = null;
  chaveAcesso = null;
  quantidadeParcelas = null;
  chaveAcessoReferencia = null;
  clienteId = null;
  clienteEmail = null;
  clienteNome = null;
  clienteCnpjCpf = null;
  vendedorId = null;
  prestadorServicoId = null;
  modalidadeFrete = null;
  freteCompoeNota = null;
  desconto = null;
  acrescimo = null;
  despesas = null;
  totalVenda = null;
  totalMercadoria = null;
  totalPagamentos = null;
  informacoesAdicionais = null;
  informacoesFisco = null;
  nfSaidaEnderecoCliente = null;
  documentoDevolucaoCliente = null;
  documentoDevolucaoFornecedor = null;
  frete = null;
  transportadoraId = null;
  transportadoraNome = null;
  transportadoraCnpjCpf = null;
  transportadoraIe = null;
  transportadoraEnderecoRua = null;
  transportadoraEnderecoMunicipio = null;
  transportadoraEnderecoUf = null;
  qtdVolumes = null;
  nfSaidaItens = null;
  nfSaidaPagamentos = null;
  devolucoesClienteIds = null;
  devolucoesFornecedorIds = null;
  nfSaidaImportadaId = null;
  orcamentoImport = null;
  pedidoImport = null;
  importacaoDocumento = null;
  vendedorObrigatorio = null;
  enderecoEntregaId = null;
  vendedorBancoCaixaId = null;

  constructor(nfSaida) {
    this.orcamentoImport = nfSaida.orcamentoImport;
    this.pedidoImport = nfSaida.pedidoImport;
    this.importacaoDocumento = nfSaida.importacaoDocumento;
    this.pedidoIds = nfSaida.pedidoIds;
    this.orcamentoIds = nfSaida.orcamentoIds;
    this.serieDocumento = nfSaida.serieDocumento;
    this.dataEmissao = nfSaida.dataEmissao || formatDate.toSend(new Date());
    this.operacaoFiscalId = nfSaida.operacaoFiscalId;
    this.grupoTributarios = nfSaida.grupoTributarios;
    this.modeloDocumento = nfSaida.modeloDocumento;
    this.notasCredito = nfSaida.notasCredito;
    this.operacaoFiscal = new OperacaoFiscal(nfSaida.operacaoFiscal);
    this.chaveAcesso = nfSaida.chaveAcesso;
    this.quantidadeParcelas = nfSaida.quantidadeParcelas;
    this.chaveAcessoReferencia = nfSaida.chaveAcessoReferencia;
    this.clienteId = nfSaida.clienteId;
    this.clienteEmail = nfSaida.clienteEmail;
    this.clienteNome = nfSaida.clienteNome;
    this.clienteCnpjCpf = nfSaida.clienteCnpjCpf;
    this.vendedorId = nfSaida.vendedorId;
    this.vendedorBancoCaixaId = nfSaida.vendedorBancoCaixaId;
    this.prestadorServicoId = nfSaida.prestadorServicoId;
    this.modalidadeFrete = nfSaida.modalidadeFrete || "mfSemFrete";
    this.transportadoraId = nfSaida.transportadoraId;
    this.transportadoraNome = nfSaida.transportadoraNome;
    this.transportadoraCnpjCpf = nfSaida.transportadoraCnpjCpf;
    this.transportadoraIe = nfSaida.transportadoraIe;
    this.transportadoraEnderecoRua = nfSaida.transportadoraEnderecoRua;
    this.transportadoraEnderecoMunicipio =
      nfSaida.transportadoraEnderecoMunicipio;
    this.transportadoraEnderecoUf = nfSaida.transportadoraEnderecoUf;
    this.qtdVolumes = nfSaida.qtdVolumes;
    this.freteCompoeNota = nfSaida.freteCompoeNota || false;
    this.desconto = nfSaida.desconto;
    this.acrescimo = nfSaida.acrescimo;
    this.despesas = nfSaida.despesas;
    this.totalMercadoria = nfSaida.totalMercadoria;
    this.totalPagamentos = nfSaida.totalPagamentos;
    this.totalVenda = nfSaida.totalVenda;
    this.informacoesAdicionais = nfSaida.informacoesAdicionais;
    this.informacoesFisco = nfSaida.informacoesFisco;
    this.nfSaidaEnderecoCliente = nfSaida.nfSaidaEnderecoCliente
      ? new NfSaidaEnderecoCliente(nfSaida.nfSaidaEnderecoCliente)
      : null;
    this.documentoDevolucaoCliente = nfSaida.documentoDevolucaoCliente;
    this.documentoDevolucaoFornecedor = nfSaida.documentoDevolucaoFornecedor;
    this.frete = nfSaida.frete;
    this.nfSaidaItens = nfSaida.nfSaidaItens?.length
      ? nfSaida.nfSaidaItens.map(
          (item) => new NfSaidaItem(item, nfSaida.operacaoFiscal)
        )
      : [];
    this.nfSaidaPagamentos = nfSaida.nfSaidaPagamentos?.length
      ? nfSaida.nfSaidaPagamentos.map(
          (pagamento) => new NfSaidaPagamento(pagamento)
        )
      : [];
    this.devolucoesClienteIds = nfSaida?.devolucoesClienteIds || null;
    this.devolucoesFornecedorIds = nfSaida?.devolucoesFornecedorIds || null;
    this.nfSaidaImportadaId = nfSaida.nfSaidaImportadaId;
    this.vendedorObrigatorio = nfSaida.vendedorObrigatorio;
    this.enderecoEntregaId = nfSaida.enderecoEntregaId;
  }
}

class NfSaidaItem {
  produtoId = null;
  unidade = null;
  quantidade = null;
  valor = null;
  subtotal = null;
  modeloTributacaoId = null;
  grupoTributacaoId = null;
  descricao = null;
  codigo = null;
  cean = null;
  codigoBarra = null;
  ncm = null;
  cfop = null;
  impostoIcmsStReducao = null;
  impostoIcmsStBase = null;
  impostoIcmsStAliquota = null;
  impostoIcmsSt = null;
  unidadeCom = null;
  unidadeTrib = null;
  quantidadeCom = null;
  quantidadeTrib = null;
  valorUnitarioComercial = null;
  valorUnitarioTributavel = null;
  desconto = null;
  acrescimo = null;
  tributosIcmsOrigem = null;
  tributosIcmsCst = null;
  tributosIcmsCsosn = null;
  tributosIcmsBaseCalculoModalidade = null;
  tributosIcmsBaseCalculoModalidadeST = null;
  tributosIcmsBaseCalculoPercentualReducao = null;
  tributosIcmsBaseCalculoValor = null;
  tributosIcmsAliquota = null;
  tributosIcmsValor = null;
  tributosPisCst = null;
  tributosPisBaseCalculoValor = null;
  tributosPisBaseCalculoQuantidade = null;
  tributosPisAliquota = null;
  tributosPisValor = null;
  tributosCofinsCst = null;
  tributosCofinsBaseCalculoValor = null;
  tributosCofinsAliquota = null;
  tributosCofinsValor = null;
  tributosIpiCst = null;
  tributosIpiEnquadramento = null;
  tributosIpiAliquota = null;
  tributosIpiValor = null;
  tributosIpiBaseCalculoValor = null;
  informacaoAdicional = null;
  modeloTributacao = null;
  saldoExportar = null;
  operacaoFiscal = null;

  constructor(item, operacaoFiscal) {
    this.produtoId = item.produtoId;
    this.unidade = item.unidade;
    this.quantidade = item.quantidade;
    this.valor = item.valor;
    this.subtotal = item.subtotal;
    this.modeloTributacaoId = item.modeloTributacaoId;
    this.grupoTributacaoId = item.grupoTributacaoId;
    this.descricao = item.descricao;
    this.codigo = item.codigo;
    this.cean = item.cean;
    this.codigoBarra = item.codigoBarra;
    this.ncm = item.ncm;
    this.cfop = item.cfop;
    this.impostoIcmsStReducao = item.impostoIcmsStReducao;
    this.impostoIcmsStBase = item.impostoIcmsStBase;
    this.impostoIcmsStAliquota = item.impostoIcmsStAliquota;
    this.impostoIcmsSt = item.impostoIcmsSt;
    this.unidadeCom = item.unidadeCom;
    this.unidadeTrib = item.unidadeTrib;
    this.quantidadeCom = item.quantidadeCom;
    this.quantidadeTrib = item.quantidadeTrib;
    this.valorUnitarioComercial = item.valorUnitarioComercial;
    this.valorUnitarioTributavel = item.valorUnitarioTributavel;
    this.desconto = item.desconto;
    this.acrescimo = item.acrescimo;
    this.tributosIcmsOrigem = item.tributosIcmsOrigem;
    this.tributosIcmsCst = item.tributosIcmsCst;
    this.tributosIcmsCsosn = item.tributosIcmsCsosn;
    this.tributosIcmsBaseCalculoModalidade =
      item.tributosIcmsBaseCalculoModalidade;
    this.tributosIcmsBaseCalculoModalidadeST =
      item.tributosIcmsBaseCalculoModalidadeST;
    this.tributosIcmsBaseCalculoPercentualReducao =
      item.tributosIcmsBaseCalculoPercentualReducao;
    this.tributosIcmsBaseCalculoValor = item.tributosIcmsBaseCalculoValor;
    this.tributosIcmsAliquota = item.tributosIcmsAliquota;
    this.tributosIcmsValor = item.tributosIcmsValor;
    this.tributosPisCst = item.tributosPisCst;
    this.tributosPisBaseCalculoValor = item.tributosPisBaseCalculoValor;
    this.tributosPisBaseCalculoQuantidade =
      item.tributosPisBaseCalculoQuantidade;
    this.tributosPisAliquota = item.tributosPisAliquota;
    this.tributosPisValor = item.tributosPisValor;
    this.tributosCofinsCst = item.tributosCofinsCst;
    this.tributosCofinsBaseCalculoValor = item.tributosCofinsBaseCalculoValor;
    this.tributosCofinsAliquota = item.tributosCofinsAliquota;
    this.tributosCofinsValor = item.tributosCofinsValor;
    this.tributosIpiCst = item.tributosIpiCst;
    this.tributosIpiEnquadramento = item.tributosIpiEnquadramento;
    this.tributosIpiAliquota = item.tributosIpiAliquota;
    this.tributosIpiValor = item.tributosIpiValor;
    this.tributosIpiBaseCalculoValor = item.tributosIpiBaseCalculoValor;
    this.informacaoAdicional = item.informacaoAdicional;
    this.modeloTributacao = item.modeloTributacao;
    this.saldoExportar = item.saldoExportar;
    this.operacaoFiscal = new OperacaoFiscal(operacaoFiscal);
  }
}

class NfSaidaPagamento {
  meioPagamentoId = null;
  condicaoPagamentoId = null;
  dataVencimento = null;
  valorTitulo = null;
  numeroTitulo = null;
  numeroAutorizacao = null;
  meioPagamentoSelecionado = null;

  constructor(nfSaidaPagamento) {
    this.meioPagamentoId = nfSaidaPagamento.meioPagamentoId;
    this.condicaoPagamentoId = nfSaidaPagamento.condicaoPagamentoId;
    this.dataVencimento = nfSaidaPagamento.dataVencimento;
    this.valorTitulo = nfSaidaPagamento.valorTitulo;
    this.numeroTitulo = nfSaidaPagamento.numeroTitulo;
    this.numeroAutorizacao = nfSaidaPagamento.numeroAutorizacao;
    this.meioPagamentoSelecionado = nfSaidaPagamento.meioPagamentoSelecionado;
  }
}
export class OperacaoFiscal {
  modeloDocumento = null;
  movimentacaoFinanceira = null;
  naturezaOperacao = null;
  movimentacaoEstoque = null;
  tipoOperacao = null;
  finalidade = null;
  naoGerarComissao = null;

  constructor(operacaoFiscal) {
    this.modeloDocumento = operacaoFiscal.modeloDocumento;
    this.movimentacaoFinanceira = operacaoFiscal.movimentacaoFinanceira;
    this.movimentacaoEstoque = operacaoFiscal.movimentacaoEstoque;
    this.tipoOperacao = operacaoFiscal.tipoOperacao;
    this.finalidade = operacaoFiscal.finalidade;
    this.naturezaOperacao = operacaoFiscal.naturezaOperacao;
    this.naoGerarComissao = operacaoFiscal.naoGerarComissao;
  }
}

class NfSaidaEnderecoCliente {
  id = null;
  complemento = null;
  codigoIbge = null;
  cep = null;
  logradouro = null;
  numero = null;
  bairro = null;
  cidade = null;
  estado = null;
  pessoaId = null;

  constructor(nfSaidaEnderecoCliente) {
    this.id = nfSaidaEnderecoCliente.id;
    this.complemento = nfSaidaEnderecoCliente?.complemento;
    this.codigoIbge = nfSaidaEnderecoCliente?.codigoIbge;
    this.cep = nfSaidaEnderecoCliente?.cep;
    this.logradouro = nfSaidaEnderecoCliente?.logradouro;
    this.numero = nfSaidaEnderecoCliente?.numero;
    this.bairro = nfSaidaEnderecoCliente?.bairro;
    this.cidade = nfSaidaEnderecoCliente?.cidade;
    this.estado = nfSaidaEnderecoCliente?.estado;
    this.pessoaId = nfSaidaEnderecoCliente?.pessoaId;
  }
}
