import { totalItensLista, calculaSubtotalParaChave } from "./";

export function totalVenda(nfSaida, nfSaidaItens, calculoTotalizadores) {
  const freteNota =
    nfSaida?.freteCompoeNota === true ? parseFloat(nfSaida?.frete ?? 0) : 0;
  const subtotalProdutos = totalItensLista(nfSaidaItens);
  const subtotalDesconto = calculaSubtotalParaChave(
    nfSaidaItens,
    calculoTotalizadores,
    "desconto"
  );
  const subtotalAcrescimo = calculaSubtotalParaChave(
    nfSaidaItens,
    calculoTotalizadores,
    "acrescimo"
  );
  const subtotalDespesas = calculaSubtotalParaChave(
    nfSaidaItens,
    calculoTotalizadores,
    "despesas"
  );
  const totalVenda =
    (subtotalProdutos || 0) -
    (subtotalDesconto || 0) +
    (subtotalAcrescimo || 0) +
    (subtotalDespesas || 0) +
    (freteNota || 0);
  return parseFloat(totalVenda.toFixed(2));
}
