import SiafLiteApiRepository from "../../external/siafLiteApi";

class MeuUsuarioService {
  constructor() {
    this.urlBase = "/painel-controle/meu-usuario";
  }

  get() {
    return new SiafLiteApiRepository(this.urlBase).get();
  }

  validarToken() {
    return new SiafLiteApiRepository(`${this.urlBase}/validar-token`).get();
  }
}

export default MeuUsuarioService;
