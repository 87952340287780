import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CurrencyInput,
  Dialog,
  DialogContent,
  Grid,
  TextFielDecimalNumbers,
} from "../../../../../components";
import {
  formatProdutosTotalizadores,
  totalVenda,
  unformatPrice,
  calculoDesconto
} from "../../../../../utils";

const DescontoDialog = ({
  open,
  setOpen,
  venda,
  listaProdutosVenda,
  setListaProdutosVenda,
  setListaPagamentosVenda,
}) => {
  const [descontoTotal, setDescontoTotal] = useState({});

  useEffect(() => {
    if (open) {
      setDescontoTotal({
        desconto: calculoDesconto(listaProdutosVenda, venda.desconto),
        descontoPorcentagem: calculoDesconto(
          listaProdutosVenda,
          venda.descontoPorcentagem,
          false
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function handleInputDesconto(e) {
    e.persist();
    const valor = unformatPrice(e.target.value);
    const descontoTemp = {
      ...descontoTotal,
      [e.target.name]: valor || 0,
    };
    if (e.target.name === "desconto") {
      descontoTemp.descontoPorcentagem = calculoDesconto(
        listaProdutosVenda,
        descontoTemp,
        false
      );
    } else {
      descontoTemp.desconto = calculoDesconto(listaProdutosVenda, descontoTemp);
    }
    setDescontoTotal(descontoTemp);
  }

  const handleSubmitDesconto = () => {
    const identificador = "desconto";
    const totalNota = totalVenda(venda, listaProdutosVenda, identificador);
    const listItensDesconto = formatProdutosTotalizadores(
      identificador,
      listaProdutosVenda,
      descontoTotal?.desconto,
      totalNota
    );
    setListaProdutosVenda(listItensDesconto);
    setListaPagamentosVenda([]);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setDescontoTotal({});
    setOpen(false);
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      PaperComponent="div"
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Card className="p-3">
          <CardHeader title="DESCONTO" />
          <CardContent className="p-3">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextFielDecimalNumbers
                  id="descontoPorcentagem"
                  name="descontoPorcentagem"
                  variant="outlined"
                  margin="none"
                  value={descontoTotal?.descontoPorcentagem ?? 0}
                  onBlur={handleInputDesconto}
                />
              </Grid>
              <Grid item xs={8}>
                <CurrencyInput
                  id="desconto"
                  name="desconto"
                  variant="outlined"
                  className="px-1"
                  margin="none"
                  value={descontoTotal.desconto ?? 0}
                  onBlur={handleInputDesconto}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmitDesconto}
            >
              <i
                className="ph-fill ph-check-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              />
              Confirmar
            </Button>
            <Button
              color="inherit"
              variant="contained"
              onClick={handleCloseDialog}
            >
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              />
              Fechar
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default DescontoDialog;
