import { Router } from "react-router-dom";
import { history } from "./services";
import "react-perfect-scrollbar/dist/css/styles.css";
import Routes from "./routes";

const App = () => {
  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
};

export default App;
