import {
  Dialog,
  DialogContent,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
} from "../../../../components";
import store from "../../../../config/store";
import { impressoraModule } from "../../../../services";

const ConfiguracoesDialog = ({ open, setOpen }) => {
  function testarImpressao() {
    impressoraModule.impressora({}, store.localInstalacao);
  }

  return (
    <Dialog
      disableBackdropClick
      fullWidth
      maxWidth="xs"
      PaperComponent="div"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Card className="p-3">
          <CardHeader title="Configurações" />
          <CardContent className="p-3">
            {impressoraModule.tipo === "usb" && (
              <Button
                variant="contained"
                color="padrao"
                onClick={testarImpressao}
              >
                testar impressao
                <i
                  className="ph-fill ph-printer"
                  style={{ fontSize: "22px", marginLeft: "10px" }}
                ></i>
              </Button>
            )}
          </CardContent>
          <CardActions>
            <Button
              color="inherit"
              variant="contained"
              onClick={() => setOpen(false)}
            >
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              />
              Fechar
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default ConfiguracoesDialog;
