import { forwardRef } from "react";
import { TextField, InputAdornment } from "@mui/material";
import NumberFormat from "react-number-format";

const TextFieldDecimalNumbers = (props) => {
  return (
    <TextField
      id={props.id}
      label={props.label}
      name={props.name}
      className={props.className}
      size={props.size}
      variant={props.variant}
      margin={props.margin ?? "normal"}
      disabled={props.disabled}
      error={props.error}
      required={props.required}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onKeyDown={props.onKeyDown}
      InputLabelProps={{
        shrink: props.shrink,
        ...props.InputLabelProps,
      }}
      onChange={props.onChange}
      value={props.value ? Number(props.value).toFixed(2) : ""}
      InputProps={{
        inputComponent: CurrencyFormatInput,
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      fullWidth={true}
    />
  );
};

const CurrencyFormatInput = forwardRef((props, ref) => {
  const formatCurrency = (value) => {
    if (!Number(value)) return "";
    const amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    })
      .format(parseFloat(value) / 100)
      .replace("R$", "")
      .trim();
    return `${amount}`;
  };

  return (
    <NumberFormat
      {...props}
      getInputRef={ref}
      fixedDecimalScale
      decimalScale={2}
      inputMode="numeric"
      displayType="input"
      format={formatCurrency}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === "" || floatValue / 100 <= 10000000000;
      }}
    />
  );
});

export default TextFieldDecimalNumbers;
