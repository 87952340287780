import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
} from "../../../../../components";
import { value } from "../../../../../helpers";
import { notification } from "../../../../../services";
import { formatDate, formatPrice } from "../../../../../utils";

const ListaPagamentosVenda = ({
  open,
  setOpen,
  meioPagamentoList,
  listaPagamentosVenda,
  setListaPagamentosVenda,
}) => {
  const colunas = [
    // {
    //   field: "condicaoPagamentoId",
    //   headerName: "Condição de Pagamento",
    //   flex: 700,
    //   valueGetter: (params) =>
    //     value.autoComplete(condicaoPagamentoList, params.value).descricao,
    // },
    {
      field: "meioPagamentoId",
      headerName: "Meio de Pagamento",
      flex: 550,
      headerClassName: "table-pdv-header",
      sortable: false,
      valueGetter: (params) =>
        value.autoComplete(meioPagamentoList, params.value).descricao,
    },
    {
      field: "valorTitulo",
      headerName: "Valor do Título",
      flex: 500,
      headerClassName: "table-pdv-header",
      sortable: false,
      valueGetter: (params) =>
        params.value > 0 ? formatPrice(params.value) : "R$ 0,00",
    },
    {
      field: "dataVencimento",
      headerName: "Vencimento",
      flex: 450,
      type: "date",
      headerClassName: "table-pdv-header",
      editable: true,
      sortable: false,
      valueGetter: (params) => {
        return formatDate.received(params.value);
      },
    },
    {
      field: "numeroAutorizacao",
      headerName: "Nº Autorização",
      flex: 500,
      headerClassName: "table-pdv-header",
      editable: true,
      sortable: false,
      valueGetter: (params) => params.value ?? "",
    },
    {
      field: "acoes",
      headerName: "Ações",
      sortable: false,
      headerClassName: "table-pdv-header",
      flex: 300,
      disableClickEventBubbling: true,
      hideable: false,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                onClickDeletePagamentos(params.id);
              }}
            >
              <Tooltip title="Excluir" arrow placement="right">
                <i className="ph-fill ph-trash" style={{ fontSize: 18 }}></i>
              </Tooltip>
            </IconButton>
          </div>
        );
      },
    },
  ];

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const onClickDeletePagamentos = async (idParcela) => {
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir essa parcela?`
    );
    if (retornoAlerta.isConfirmed) {
      /* const { valorTitulo } = listaPagamentosVenda.find(
        ({ id }) => id == idParcela
      ); */
      const data = listaPagamentosVenda.filter((item) => item.id !== idParcela);
      /*  setGerarPagamentos({
        ...gerarPagamentos,
        valorTitulo: gerarPagamentos?.valorTitulo + valorTitulo.toFixed(2) * 1,
      }); */
      setListaPagamentosVenda(data);
    }
  };

  const onCellKeyDown = (params, event) => {
    if (event.key === "Enter") {
      setListaPagamentosVenda((prevState) => {
        return prevState.map((row) => {
          if (params.row.meioPagamentoId === row.meioPagamentoId) {
            return {
              ...row,
              [params.field]: event.target.value
                ? `${event.target.value}`.toUpperCase()
                : null,
            };
          }
          return row;
        });
      });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      PaperComponent="div"
      open={open}
      aria-labelledby="form-dialog-title"
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <Card className="p-3">
          <CardHeader title="Pagamentos" />
          <CardContent className="p-3">
            <DataGrid
              rows={listaPagamentosVenda}
              columns={colunas}
              paginationMode="client"
              disableRowSelectionOnClick
              autoHeight
              slots={{
                noRowsOverlay: () => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Box>Sem Pagamentos</Box>
                    </div>
                  );
                },
              }}
              onCellKeyDown={onCellKeyDown}
              disableColumnMenu
              hideFooter
              hideFooterPagination
              hideFooterSelectedRowCount
            />
          </CardContent>
          <CardActions>
            <Button
              color="inherit"
              variant="contained"
              onClick={handleCloseDialog}
            >
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              />
              Fechar
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default ListaPagamentosVenda;
