import {
  Box,
  Button,
  CurrencyInput,
  Grid,
  TextField,
  Tooltip,
} from "../../../components";
//import notFoundImage from "../../assets/images/not-found.png";
import { value } from "../../../helpers";
import { unformatPrice } from "../../../utils";
import { useEffect } from "react";

const PdvProdutoView = ({
  produto,
  item,
  setItem,
  userLog,
  handleAdicionarProduto,
  isProdutoEdit,
}) => {
  useEffect(() => {
    if (produto) {
      document.getElementById("buttonAdicionar").focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    setItem((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputBlur = (e) => {
    setItem((prevState) => ({
      ...prevState,
      [e.target.name]: unformatPrice(e.target.value),
    }));
  };

  return (
    <Box>
      <Grid item xs={12}>
        <h4
          className="text-uppercase"
          style={{ fontWeight: 700 }}
        >{`${produto.codigo} - ${produto.descricao}`}</h4>
        <hr className="mt-4" />
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <Tooltip title="Unidade de venda" placement="bottom">
            <TextField
              id="unidade"
              name="unidade"
              label="Unidade"
              variant="outlined"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              value={item?.unidade ? item.unidade : ""}
              onChange={handleInputChange}
              disabled={true}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="quantidade"
            name="quantidade"
            label="Quantidade"
            variant="outlined"
            margin="normal"
            type="number"
            InputProps={
              item?.fracionavel
                ? { inputProps: { min: 0.01, step: 0.01 } }
                : { inputProps: { min: 1, step: 1 } }
            }
            value={value.text(item?.quantidade)}
            onChange={(e) => {
              if (e.target.value <= 0) e.target.value = "";
              if (produto?.fracionavel) {
                e.target.value = Number(e.target.value);
              } else {
                e.target.value = Math.trunc(Number(e.target.value));
              }
              handleInputChange(e);
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <CurrencyInput
            id="valor"
            name="valor"
            label="Valor Unitário R$"
            value={item?.valor}
            variant={"outlined"}
            onBlur={handleInputBlur}
          />
        </Grid>
        <Grid item xs={3}>
          <CurrencyInput
            id="subTotal"
            name="subTotal"
            label="Total Item R$"
            variant="outlined"
            disabled={true}
            value={value.text(item?.valor * item?.quantidade)}
            onBlur={handleInputBlur}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip title="Quantidade em estoque" placement="bottom">
            <TextField
              id="estoque"
              name="estoque"
              label="Estoque"
              variant="outlined"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              value={item?.estoqueAtual ? item.estoqueAtual : 0}
              disabled={true}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" className="mt-1">
        <Grid item xs={4}>
          <hr />
        </Grid>
        <Grid item xs={4}>
          {isProdutoEdit ? (
            <Button
              id="buttonAdicionar"
              variant="contained"
              size="small"
              fullWidth
              sx={{
                height: "60px",
                borderRadius: "10px",
                boxShadow: "none",
              }}
              onClick={() => handleAdicionarProduto(produto, item)}
            >
              <i
                className="ph-bold ph-arrows-clockwise"
                style={{ color: "#fff", fontSize: 20, marginRight: 10 }}
              ></i>
              <span style={{ margin: 5, fontSize: 10 }}>ATUALIZAR PRODUTO</span>
            </Button>
          ) : (
            <Button
              id="buttonAdicionar"
              variant="contained"
              color="success"
              size="small"
              fullWidth
              sx={{
                height: "60px",
                borderRadius: "10px",
                boxShadow: "none",
              }}
              onClick={() => handleAdicionarProduto(produto, item)}
            >
              <i
                className="ph-fill ph-plus-circle"
                style={{ color: "#fff", fontSize: 20, marginRight: 10 }}
              ></i>
              <span style={{ margin: 5, fontSize: 10 }}>ADICIONAR PRODUTO</span>
            </Button>
          )}
        </Grid>
        <Grid item xs={4}>
          <hr />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PdvProdutoView;
