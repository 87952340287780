import SiafLiteApiRepository from "../../external/siafLiteApi";

class NfSaidaService {
  constructor() {
    this.urlBase = "/faturamento/nf-saida";
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  getAllFiltroAvancado(body) {
    return new SiafLiteApiRepository(this.urlBase).patch(body);
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  getVinculoOperacaoFiscal(operacaoFiscalId) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${operacaoFiscalId}/vinculo-operacao-fiscal`
    ).get();
  }

  cadastrar(body) {
    return new SiafLiteApiRepository(this.urlBase).post(body);
  }

  atualizar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(id, body);
  }

  deletar(id) {
    return new SiafLiteApiRepository(this.urlBase).delete(id);
  }

  getResumoSaidasTotais() {
    return new SiafLiteApiRepository(
      `${this.urlBase}/estatistica/resumo-saidas-totais`
    ).get();
  }

  getVendasPorMes() {
    return new SiafLiteApiRepository(
      `${this.urlBase}/estatistica/resumo-saidas-mes`
    ).get();
  }

  getVendasPorHora() {
    return new SiafLiteApiRepository(
      `${this.urlBase}/estatistica/resumo-saidas-hora`
    ).get();
  }

  getVendas() {
    return new SiafLiteApiRepository(
      `${this.urlBase}/estatistica/resumo-saidas`
    ).get();
  }

  getAllAutoSequencia(query) {
    return new SiafLiteApiRepository(`${this.urlBase}/autoSequencia/lista`).get(
      query
    );
  }

  getRelatorioResumido(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/resumido`
    ).patch(body);
  }

  getRelatorioResumidoContador(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/resumido-contador`
    ).patch(body);
  }

  getRelatorioComissaoVendedores(body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/relatorios/comissao-vendedores`
    ).patch(body);
  }

  cancelar(id, body) {
    return new SiafLiteApiRepository(this.urlBase).put(
      id,
      body,
      "nota-fiscal/cancelamento"
    );
  }

  getDadosXml(id) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${id}/nota-fiscal/xml/dados`
    ).get();
  }

  getArquivoXml(id) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${id}/nota-fiscal/xml/arquivo`
    ).get();
  }

  emitirNotaFiscal(id) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${id}/nota-fiscal/emitir`
    ).get();
  }

  validarNotaFiscal(id) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${id}/nota-fiscal/valida`
    ).get();
  }

  cartaCorrecao(id, body) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${id}/nota-fiscal/carta-correcao`
    ).post(body);
  }

  setCodigoManual(body) {
    return new SiafLiteApiRepository(`${this.urlBase}/autoSequencia`).post(
      body
    );
  }

  consultarStatusNotaFiscal(id) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${id}/nota-fiscal/consulta-status`
    ).get();
  }

  enviarNotaFiscalContigencia(id) {
    return new SiafLiteApiRepository(
      `${this.urlBase}/${id}/nota-fiscal/envia-contigencia`
    ).get();
  }

  inutilizacaoDfe(sequencia) {
    return new SiafLiteApiRepository(`${this.urlBase}/inutilizar-dfe`).post(
      sequencia
    );
  }
}

export default NfSaidaService;
