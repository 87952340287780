import { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CurrencyInput,
  Grid,
  ListItemButton,
  ListItemText,
  TextField,
} from "../../../../../components";
import { value } from "../../../../../helpers";
import { formatPrice, unformatPrice } from "../../../../../utils";
import Mousetrap from "mousetrap";

const AdicionarPagamentoDialogContent = ({
  activeStep,
  setActiveStep,
  meioPagamentoList,
  condicaoPagamentoList,
  pagamentoSelecionado,
  setPagamentoSelecionado,
  condicoesFiltradas,
  setCondicoesFiltradas,
  condicaoSelecionada,
  setCondicaoSelecionada,
  filtrarCondicoesPagamento,
  valorPagamento,
  setValorPagamento,
  numeroAutorizacao,
  setNumeroAutorizacao,
  venda,
  onSubmitParcelas,
  inputError,
}) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const itemRefs = useRef([]);

  const atalhosPdv = [
    {
      keys: ["arrow+down"],
      action: () => {
        setFocusedIndex((prev) =>
          Math.min(prev + 1, itemRefs.current.length - 1)
        );
        return false;
      },
    },
    {
      keys: ["arrow+up"],
      action: () => {
        setFocusedIndex((prev) => Math.max(prev - 1, 0));
        return false;
      },
    },
  ];

  atalhosPdv.map(({ keys, action }) => Mousetrap.bind(keys, action));

  useEffect(() => {
    if (itemRefs?.current[focusedIndex]) {
      itemRefs.current[focusedIndex].focus();
    }
  }, [focusedIndex, itemRefs]);

  useEffect(() => {
    const valorInput = document.getElementById("valorPagamentoPdv");
    if (activeStep === 1 && condicaoSelecionada && valorInput) {
      valorInput.focus();
    }
  }, [activeStep, condicaoSelecionada]);

  const handleSelecionarPagamento = (pagamento) => {
    setPagamentoSelecionado(pagamento);
    const condicoes = filtrarCondicoesPagamento(pagamento);
    setCondicoesFiltradas(condicoes);
    if (pagamento?.condicoesPagamento.length === 1) {
      setCondicaoSelecionada(pagamento.condicoesPagamento[0]);
    }
    setActiveStep(1);
  };

  const handleChangeValor = (event) => {
    setValorPagamento(parseFloat(unformatPrice(event.target.value)));
  };

  const handleChangeAutorizacao = (event) => {
    setNumeroAutorizacao(
      event.target.value ? `${event.target.value}`.toUpperCase() : null
    );
  };

  const troco = () => {
    const saldoAPagar =
      parseFloat(venda?.totalVenda ?? 0) -
      parseFloat(venda?.totalPagamentos ?? 0);
    const valor =
      valorPagamento > saldoAPagar ? valorPagamento - saldoAPagar : 0;
    return formatPrice(valor);
  };

  switch (activeStep) {
    case 1:
      return (
        <Grid container spacing={2}>
          <HeaderAdicionarPagamento
            pagamentoSelecionado={pagamentoSelecionado}
            venda={venda}
          />
          <Grid item xs={12}>
            {condicaoSelecionada ? (
              <>
                <div className="d-flex">
                  <Grid item xs={11}>
                    <Autocomplete
                      id="condicaoPagamentoId"
                      name="condicaoPagamentoId"
                      options={condicaoPagamentoList}
                      autoHighlight
                      disabled={true}
                      getOptionLabel={(option) => option?.descricao ?? ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          label="Condição de Pagamento"
                          variant={"outlined"}
                        />
                      )}
                      value={value.autoComplete(
                        condicaoPagamentoList,
                        condicaoSelecionada?.id
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className="d-flex align-items-center justify-content-end"
                  >
                    <Button
                      size="small"
                      sx={{
                        background: "none",
                        height: "35px",
                        minWidth: "15px",
                        boxShadow: "none",
                        marginTop: 1,
                      }}
                      onClick={() => setCondicaoSelecionada(null)}
                    >
                      <i className="ph-bold ph-x" style={{ fontSize: 20 }}></i>
                    </Button>
                  </Grid>
                </div>
                <Grid item xs={12}>
                  <CurrencyInput
                    id="valorPagamentoPdv"
                    name="valorPagamentoPdv"
                    label="Valor Recebido"
                    variant="outlined"
                    required
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onSubmitParcelas(e);
                      }
                    }}
                    error={inputError === "valorPagamentoPdv"}
                    value={value.text(valorPagamento)}
                    onChange={handleChangeValor}
                    fullWidth
                  />
                </Grid>
                {pagamentoSelecionado?.cartaoCreditoDebito && (
                  <Grid item xs={12}>
                    <TextField
                      id="numeroAutorizacao"
                      name="numeroAutorizacao"
                      label="Número Autorização"
                      margin="normal"
                      required
                      error={inputError === "numeroAutorizacao"}
                      value={value.text(numeroAutorizacao)}
                      onChange={handleChangeAutorizacao}
                      fullWidth
                    />
                  </Grid>
                )}
                {pagamentoSelecionado?.nfePagamento === "fpDinheiro" && (
                  <>
                    <h6 className="centralizar mt-3">Troco:</h6>
                    <h6 id="valor-saldo-operacao">{troco()}</h6>
                  </>
                )}
              </>
            ) : (
              <Box className="teste-layout mt-2">
                {condicoesFiltradas?.length ? (
                  condicoesFiltradas.map((condicao, index) => (
                    <ListItemButton
                      key={index}
                      ref={(ref) => (itemRefs.current[index] = ref)}
                      className="teste-layout-button"
                      style={{ borderRadius: 15 }}
                      onClick={() => setCondicaoSelecionada(condicao)}
                    >
                      <ListItemText primary={condicao?.descricao} />
                    </ListItemButton>
                  ))
                ) : (
                  <div className="d-flex flex-column align-items-center">
                    <i
                      className={"ph-bold ph-credit-card"}
                      style={{
                        fontSize: 80,
                        color: "#d5d5d5",
                      }}
                    ></i>
                    <p>SEM CONDIÇÃO VINCULADA</p>
                  </div>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      );
    default:
      return (
        <Box className="teste-layout">
          {meioPagamentoList?.length ? (
            meioPagamentoList
              .filter((pagamento) => pagamento.nfePagamento !== "fpCreditoLoja")
              .map((pagamento, index) => (
                <ListItemButton
                  key={index}
                  ref={(ref) => (itemRefs.current[index] = ref)}
                  className="teste-layout-button"
                  style={{ borderRadius: 15 }}
                  onClick={() => handleSelecionarPagamento(pagamento)}
                >
                  <ListItemText primary={pagamento?.descricao} />
                </ListItemButton>
              ))
          ) : (
            <div className="d-flex flex-column align-items-center">
              <i
                className={"ph-fill ph-user"}
                style={{
                  fontSize: 80,
                  color: "#d5d5d5",
                }}
              ></i>
              <p>SEM PAGAMENTO</p>
            </div>
          )}
        </Box>
      );
  }
};

function HeaderAdicionarPagamento({ pagamentoSelecionado, venda }) {
  return (
    <Grid
      container
      sx={{
        p: "1em 0 1em 17px",
      }}
    >
      <Grid item xs={7}>
        <div>
          <h7 className="header-pagamento">Meio de Pagamento: </h7>
          <br />
          <strong className="valor-pagamento-meio">
            {pagamentoSelecionado.descricao}
          </strong>
        </div>
      </Grid>
      <Grid item xs={5} className="d-flex justify-content-end">
        <div>
          <h7 className="header-pagamento">Saldo a Pagar: </h7>
          <br />
          <strong className="valor-pagamento-saldo">
            {formatPrice(
              parseFloat(venda?.totalVenda ?? 0) -
                parseFloat(venda?.totalPagamentos ?? 0)
            )}
          </strong>
        </div>
      </Grid>
    </Grid>
  );
}

export default AdicionarPagamentoDialogContent;
