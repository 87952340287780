import { useState } from "react";
import { Menu, MenuItem, ListItemIcon } from "../../../components";
import ConfiguracoesDialog from "./dialog/configuracoes-dialog.component";
import shortid from "shortid";

const MenuAddActions = ({ anchorEl, setAnchorEl }) => {
  const [openConfiguracaoDialog, setOpenConfiguracaoDialog] = useState(false);
  const handleAddActionsClose = () => {
    setAnchorEl(null);
  };
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-add-actions-menu";

  const handleOpenConfiguracoes = () => {
    setOpenConfiguracaoDialog(true);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        elevation={1}
        getContentAnchorEl={null}
        id={menuId}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        autoFocus={false}
        open={isMenuOpen}
        onClose={handleAddActionsClose}
        className="m-3"
      >
        {[
          <MenuItem
            className="mb-1"
            onClick={handleOpenConfiguracoes}
            key={shortid.generate()}
          >
            <ListItemIcon style={{ minWidth: 42 }}>
              <i
                className="ph-fill ph-gear"
                style={{ fontSize: 23, marginRight: 16 }}
              ></i>
            </ListItemIcon>
            Configurações
          </MenuItem>,
        ]}
      </Menu>
      <ConfiguracoesDialog
        open={openConfiguracaoDialog}
        setOpen={setOpenConfiguracaoDialog}
      />
    </>
  );
};

export default MenuAddActions;
