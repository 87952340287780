import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  FormControl,
  Autocomplete,
  CurrencyInput,
  Typography,
} from "../../../../components";
import {
  formatDate,
  formatPrice,
  responseErros,
  unformatPrice,
} from "../../../../utils";
import "../../pdv-style.scss";
import {
  CaixaService,
  BancosCaixasResponsaveisService,
  BancosCaixasService,
  SessionService,
  notification,
} from "../../../../services";
import { value, InputErros, StorageHelper } from "../../../../helpers";

const iconeCaixa = require("../../../../assets/images/cashregister.gif");

const SalvarStep = ({
  listaSaldos,
  setActiveStep,
  setMovimentacaoCaixa,
  totalSaldoInicial,
  handleAdicionarSaldoAbrirCaixa,
  meioPagamentoList,
}) => {
  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={12} className="d-flex justify-content-center">
        <label className="weight">Saldo Inicial</label>
      </Grid>
      <Grid item xs={12} className="d-flex justify-content-center">
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setActiveStep(2);
            setMovimentacaoCaixa({
              valor: 0,
              tipo: "ENTRADA",
            });
          }}
        >
          <i
            className="ph-fill ph-plus-circle"
            style={{ color: "#fff", fontSize: 20, margin: 8 }}
          ></i>
          Adicionar Saldo Inicial
        </Button>
      </Grid>
      {listaSaldos.map((item) => (
        <Grid item xs={12} key={item.id}>
          <div className="lista-saldos">
            {
              value.autoComplete(meioPagamentoList, item.meioPagamentoId)
                .descricao
            }
            <span className="ms-3">
              {item.valor ? formatPrice(item.valor) : "R$ 0,00"}
            </span>
          </div>
        </Grid>
      ))}
      <Grid item xs={12} className="d-flex justify-content-center">
        <Typography
          className="fw-bold"
          sx={{
            color: "#43A047",
          }}
          variant="h1"
        >
          {listaSaldos.length && totalSaldoInicial(listaSaldos)
            ? formatPrice(totalSaldoInicial(listaSaldos))
            : "R$ 0,00"}
        </Typography>
      </Grid>
      <Grid item xs={12} className="d-flex justify-content-center mb-3">
        <Button
          color="success"
          variant="contained"
          onClick={handleAdicionarSaldoAbrirCaixa}
        >
          <i
            className="ph-fill ph-shopping-cart"
            style={{ color: "#fff", fontSize: 20, margin: 8 }}
          ></i>
          Salvar e ir ao pdv
        </Button>
      </Grid>
    </Grid>
  );
};

const AdicionarSaldoStep = ({
  movimentacaoCaixa,
  handleInputChange,
  meioPagamentoList,
  setListaSaldos,
  setActiveStep,
  meioPagamentoPadrao,
}) => {
  const [meioPagamento, setMeioPagamento] = useState(meioPagamentoPadrao);

  const handleAdicionarSaldo = () => {
    setListaSaldos((prevState) => [
      ...prevState,
      {
        ...movimentacaoCaixa,
        meioPagamentoId: meioPagamento.id,
      },
    ]);
    setActiveStep(1);
  };

  return (
    <form onSubmit={handleAdicionarSaldo} className="p-2">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CurrencyInput
            id="valor-saldo"
            name="valor"
            label="Saldo Inicial"
            value={movimentacaoCaixa.valor}
            onBlur={(e) => handleInputChange(e)}
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="meioPagamento"
              name="meioPagamento"
              options={meioPagamentoList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => option.descricao}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meio de Pagamento"
                  variant="outlined"
                  required
                />
              )}
              onChange={(_, newValue) => setMeioPagamento(newValue)}
              value={meioPagamento}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} className="my-3">
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Adicionar Saldo
          <i
            className="ph-fill ph-coins"
            style={{ color: "#fff", fontSize: 20, margin: 8 }}
          ></i>
        </Button>
        {
          <Button
            className={"mt-3"}
            onClick={() => {
              setActiveStep(1);
            }}
            variant="contained"
            color="padrao"
            fullWidth
          >
            <i
              className="ph-fill ph-arrow-u-up-left"
              style={{ fontSize: 20, margin: 8 }}
            ></i>
            Voltar
          </Button>
        }
      </Grid>
    </form>
  );
};

const AbrirCaixaStep = ({
  contaLista,
  inputErros,
  formCaixa,
  setFormCaixa,
  abrirCaixaPadrao,
  bancoCaixa,
  setBancoCaixa,
}) => {
  const onChangeAutocomplete = (name, value) => {
    setBancoCaixa(value);
    setFormCaixa({
      ...formCaixa,
      [name]: value ? value.id : undefined,
    });
  };

  return (
    <form onSubmit={(event) => abrirCaixaPadrao(event)}>
      <Grid container spacing={1}>
        <Grid item xs={12} className="d-flex justify-content-center">
          <img src={iconeCaixa} width={"170px"} />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="bancoCaixaId"
            name="bancoCaixaId"
            options={contaLista}
            noOptionsText="Sem opções"
            autoHighlight
            getOptionLabel={(option) => (option.nome ? option.nome : "")}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Caixa"
                margin="normal"
                fullWidth
                required
                variant="outlined"
                error={inputErros.get("bancoCaixaId")}
              />
            )}
            onChange={(e, value) => onChangeAutocomplete("bancoCaixaId", value)}
            value={bancoCaixa}
          />
        </Grid>
        <Grid item xs={12} className="mb-3">
          <Button
            variant="contained"
            disabled={!contaLista.length ? true : false}
            color="primary"
            fullWidth
            type="submit"
          >
            Abrir Caixa
            <i
              className="ph-bold ph-storefront"
              style={{ color: "#fff", fuontSize: 20, margin: 8 }}
            ></i>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const SaldoPdvDialog = ({
  bancoCaixa,
  setBancoCaixa,
  openDialogSaldo,
  setOpenDialogSaldo,
  validarToken,
  setLoading,
  setDataAberturaCaixa,
  meioPagamentoList,
  meioPagamentoPadrao,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [listaSaldos, setListaSaldos] = useState([]);
  const [contaLista, setContaLista] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const [formCaixa, setFormCaixa] = useState({
    data: new Date(),
  });
  const [movimentacaoCaixa, setMovimentacaoCaixa] = useState({
    valor: 0,
    tipo: "ENTRADA",
  });
  const sessionService = new SessionService();
  const bancosCaixasResponsaveisService = new BancosCaixasResponsaveisService();
  const bancosCaixasService = new BancosCaixasService();
  const caixaService = new CaixaService();
  const storageHelper = new StorageHelper();
  const inputErros = new InputErros(inputErro, setInputErro);
  const UNAUTHORIZED = 401;

  useEffect(() => {
    if (openDialogSaldo) {
      buscarBancoCaixaResponsavel();
    }
  }, [openDialogSaldo]);

  const handleAvancarStep = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  const buscarBancoCaixa = (data) => {
    const filtro = {
      nonPaginated: true,
      id: data,
    };
    bancosCaixasService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setContaLista(result.data);
        return;
      } else responseErros(result);
      if (result.response.status === UNAUTHORIZED) {
        setOpenDialogSaldo(false);
        validarToken();
        return;
      }
    });
  };

  const buscarBancoCaixaResponsavel = async () => {
    setLoading(true);
    const user = sessionService.getUsuario();
    const result = await bancosCaixasResponsaveisService.getAll();
    if (!result.isAxiosError) {
      const data = result.data.rows.filter((item) => item.usuarioId == user.id);
      const arrayData = data.map((bancosIds) => bancosIds.bancoCaixaId);
      if (arrayData?.length) {
        buscarBancoCaixa(arrayData);
      } else
        notification.alertaGenericos(
          "Você não tem acesso a nenhum caixa. Entre em contato com o administrador do sistema"
        );
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const handleInputChange = (event) => {
    if (event.target.name == "valor") {
      setMovimentacaoCaixa((prevState) => ({
        ...prevState,
        tipo: "ENTRADA",
        valor: parseFloat(unformatPrice(event.target.value)),
      }));
    } else {
      setMovimentacaoCaixa((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const totalSaldoInicial = (listaSaldos) => {
    return (
      listaSaldos
        .map((item) => item.valor)
        .reduce((acumulador, total) => acumulador + total) ?? 0
    );
  };

  const abrirCaixaPadrao = async (event) => {
    event.preventDefault();
    setLoading(true);
    const result = await caixaService.caixaAberto(
      new Date(),
      formCaixa.bancoCaixaId
    );
    setLoading(false);
    if (result.isAxiosError) {
      if (result.response.status === UNAUTHORIZED) {
        setOpenDialogSaldo(false);
        validarToken();
      } else {
        responseErros(result);
      }
      return;
    }
    if (result.data) {
      setOpenDialogSaldo(false);
      storageHelper.setLocal("bancoCaixaIdPdv", formCaixa.bancoCaixaId);
      reiniciarSteap();
      return;
    }
    handleAvancarStep();
  };

  const handleAdicionarSaldoAbrirCaixa = async () => {
    setLoading(true);
    const body = {
      data: formCaixa.data,
      bancoCaixaId: formCaixa.bancoCaixaId,
      aberto: true,
    };
    const resultCaixaCadastrar = await caixaService.cadastrar(body);
    if (resultCaixaCadastrar.isAxiosError) {
      reiniciarSteap();
      if (resultCaixaCadastrar.response.status === UNAUTHORIZED) {
        validarToken();
      } else {
        responseErros(resultCaixaCadastrar);
      }
      setLoading(false);
      return;
    }
    storageHelper.setLocal("bancoCaixaIdPdv", formCaixa.bancoCaixaId);
    setDataAberturaCaixa(formatDate.toSend(formCaixa.data));
    const listaSaldosCadastrar = listaSaldos.filter((saldo) => saldo.valor > 0);
    if (listaSaldosCadastrar.length) {
      const resultCaixaMovimentar = await caixaService.movimentar(
        formCaixa.bancoCaixaId,
        listaSaldosCadastrar
      );
      if (resultCaixaMovimentar.isAxiosError) {
        responseErros(resultCaixaMovimentar);
      }
    }
    setLoading(false);
    reiniciarSteap();
    validarToken();
  };

  const reiniciarSteap = () => {
    setActiveStep(0);
    setFormCaixa({
      data: new Date(),
    });
    setMovimentacaoCaixa({
      valor: 0,
      tipo: "ENTRADA",
    });
    setListaSaldos([]);
    setOpenDialogSaldo(false);
  };

  const conteudoDialogo = (activeStep) => {
    switch (activeStep) {
      case 1:
        return (
          <SalvarStep
            listaSaldos={listaSaldos}
            setActiveStep={setActiveStep}
            setMovimentacaoCaixa={setMovimentacaoCaixa}
            totalSaldoInicial={totalSaldoInicial}
            handleAvancarStep={handleAvancarStep}
            handleAdicionarSaldoAbrirCaixa={handleAdicionarSaldoAbrirCaixa}
            meioPagamentoList={meioPagamentoList}
          />
        );
      case 2:
        return (
          <AdicionarSaldoStep
            movimentacaoCaixa={movimentacaoCaixa}
            handleInputChange={handleInputChange}
            setActiveStep={setActiveStep}
            meioPagamentoList={meioPagamentoList}
            meioPagamentoPadrao={meioPagamentoPadrao}
            setListaSaldos={setListaSaldos}
          />
        );
      default:
        return (
          <AbrirCaixaStep
            bancoCaixa={bancoCaixa}
            setBancoCaixa={setBancoCaixa}
            contaLista={contaLista}
            inputErros={inputErros}
            formCaixa={formCaixa}
            setFormCaixa={setFormCaixa}
            abrirCaixaPadrao={abrirCaixaPadrao}
          />
        );
    }
  };

  return (
    <Dialog
      PaperProps={{
        className: "dialog-pdv",
      }}
      fullWidth
      maxWidth="xs"
      open={openDialogSaldo}
    >
      <DialogContent>{conteudoDialogo(activeStep)}</DialogContent>
    </Dialog>
  );
};

export default SaldoPdvDialog;
