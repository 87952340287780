import {
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
  MaskedInput,
} from "../../../../../components";

export default function IdentificarClienteDialogContent({
  cliente,
  clienteAvulso,
  setClienteAvulso,
  inputErros,
}) {
  const handleInputChange = (event) => {
    const clientesTemp = {
      ...clienteAvulso,
      [event.target.name]: event.target.value || "",
    };
    if (event.target.name === "naturezaSocial") {
      clientesTemp.cnpjCpf = "";
      clientesTemp.nomeRazaoSocial = clientesTemp?.nomeRazaoSocial || "";
    }
    setClienteAvulso(clientesTemp);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="nomeRazaoSocial"
            name="nomeRazaoSocial"
            variant="outlined"
            disabled={cliente?.nomeRazaoSocial}
            fullWidth
            label="Cliente"
            value={clienteAvulso?.nomeRazaoSocial ?? ""}
            error={
              clienteAvulso?.naturezaSocial === "juridica"
                ? inputErros.get("nomeRazaoSocial")
                : false
            }
            onChange={handleInputChange}
            required={clienteAvulso?.naturezaSocial === "juridica"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-3">
        <Grid item xs={6}>
          <Select
            label="Natureza Social"
            name="naturezaSocial"
            id="naturezaSocial"
            disabled={cliente?.nomeRazaoSocial}
            onChange={handleInputChange}
            value={clienteAvulso?.naturezaSocial ?? "fisica"}
            error={inputErros.get("naturezaSocial")}
          >
            <MenuItem value={"fisica"}>Física</MenuItem>
            <MenuItem value={"juridica"}>Jurídica</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <MaskedInput
            label={
              clienteAvulso?.naturezaSocial === "juridica" ? "CNPJ" : "CPF"
            }
            name="cnpjCpf"
            variant="outlined"
            margin="none"
            disabled={cliente?.nomeRazaoSocial}
            onChange={handleInputChange}
            mask={
              clienteAvulso?.naturezaSocial === "juridica"
                ? "99.999.999/9999-99"
                : "999-999-999-99"
            }
            value={clienteAvulso?.cnpjCpf ?? ""}
            error={inputErros.get("cnpjCpf")}
            required={clienteAvulso?.naturezaSocial === "juridica"}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
