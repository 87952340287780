import { useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Autocomplete,
  Grid,
  TextField,
} from "../../../../../components";
import { IdentificarClienteDialogContent } from "../..";
import { InputErros, ValidarCpfCnpjHelper } from "../../../../../helpers";
import {
  ContasReceberService,
  PessoaService,
  notification,
} from "../../../../../services";
import { responseErros } from "../../../../../utils";

export default function IdentificarClienteDialog({
  open,
  setOpen,
  setClienteSelecionado,
  setListaNotasCredito,
}) {
  const inputCliente = useRef();
  const [clienteAvulso, setClienteAvulso] = useState({});
  const [cliente, setCliente] = useState({});
  const [clienteList, setClienteList] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const pessoaService = new PessoaService();
  const validarCpfCnpjHelper = new ValidarCpfCnpjHelper();
  const contasReceberService = new ContasReceberService();
  const inputErros = new InputErros(inputErro, setInputErro);

  const handleCloseDialog = () => {
    setCliente({});
    setClienteAvulso({});
    setClienteList([]);
    setOpen(false);
  };

  const buscarCliente = async (filtros, tiposPessoaId) => {
    const filtro = {
      ...filtros,
      tiposPessoaId,
      nonPaginated: true,
      ativado: true,
    };
    pessoaService.getAll(filtro).then((res) => {
      if (!res.isAxiosError) {
        setClienteList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const handleAdicionarCliente = () => {
    if (cliente?.nomeRazaoSocial) {
      setClienteSelecionado(cliente);
      handleCloseDialog();
    } else {
      if (
        clienteAvulso?.cnpjCpf === "" &&
        clienteAvulso?.naturezaSocial !== ""
      ) {
        inputErros.gerar("cnpjCpf");
        return notification.alertaGenericos(
          "Insira um documento para identificar o cliente"
        );
      } else if (clienteAvulso?.cnpjCpf) {
        const cpfValido =
          clienteAvulso.cnpjCpf?.length <= 11
            ? validarCpfCnpjHelper.cpf(clienteAvulso.cnpjCpf)
            : validarCpfCnpjHelper.cnpj(clienteAvulso.cnpjCpf);
        if (!cpfValido) {
          inputErros.gerar("cnpjCpf");
          return notification.alertaGenericos("Documento inválido");
        } else {
          setClienteSelecionado(clienteAvulso);
          handleCloseDialog();
        }
      } else handleCloseDialog();
    }
    inputErros.limpar();
  };

  const onSearchChangePessoas = (event, tiposPessoaId) => {
    if (
      event.key === "Enter" &&
      inputCliente.current === document.activeElement
    ) {
      const eventValue = event.target?.value;
      let filtros = {
        nomeRazaoSocial: eventValue,
        apelidoFantasia: eventValue,
      };
      buscarCliente(filtros, tiposPessoaId);
    }
  };

  const buscarNotasCredito = (pagadorId) => {
    const filtro = {
      pagadorId,
      tipo: "NOTA_CREDITO",
      status: ["ABERTO", "BAIXADO_PARCIAL"],
      restritiva: true,
    };
    contasReceberService.getAllFiltroAvancado(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaNotasCredito(result.data.rows);
      } else responseErros(result);
    });
  };

  const handleChangeInput = (value) => {
    buscarNotasCredito(value?.id);
    setCliente(value ? value : null);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperComponent="div"
      open={open}
      aria-labelledby="form-dialog-title"
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <Card className="p-3">
          <CardHeader title="Identificar Cliente" />
          <CardContent className="p-3">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  id="clienteId"
                  name="clienteId"
                  options={clienteList}
                  autoHighlight
                  fullWidth
                  getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
                  loadingText="Carregando"
                  noOptionsText={
                    document.getElementById("clienteId")?.value.length >= 1
                      ? "Nenhum cliente encontrado"
                      : "Digite e pressione Enter"
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Buscar Cliente"
                      error={inputErros.get("clienteId")}
                      variant={"outlined"}
                      onKeyDown={(e) => onSearchChangePessoas(e, 1)}
                      inputRef={inputCliente}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  onChange={(_, newValue) => handleChangeInput(newValue)}
                  value={cliente}
                />
              </Grid>
              <Grid item xs={12}>
                <hr></hr>
              </Grid>
              <Grid item xs={12}>
                <IdentificarClienteDialogContent
                  cliente={cliente}
                  clienteAvulso={clienteAvulso}
                  setClienteAvulso={setClienteAvulso}
                  inputErros={inputErros}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              type="submit"
              variant="contained"
              disableElevation
              onClick={(event) => handleAdicionarCliente(event)}
            >
              <i
                className="ph-fill ph-check-circle"
                style={{ color: "white", fontSize: 22, marginRight: 10 }}
              ></i>
              Salvar
            </Button>
            <Button
              color="inherit"
              variant="contained"
              disableElevation
              onClick={handleCloseDialog}
            >
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              />
              Fechar
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
}
