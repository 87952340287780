export function calcularPrecoProduto(
  produto,
  preco,
  tabelaPrecoCliente,
  condicaoPagamento
) {
  if (
    tabelaPrecoCliente?.length > 0 &&
    condicaoPagamento === tabelaPrecoCliente.condicaoPagamentoId
  ) {
    if (tabelaPrecoCliente.tipoTabela === "Por produto") {
      const tabelaPrecoProduto = tabelaPrecoCliente.tabelaPrecoProdutos.find(
        (item) => item.produtoId === produto.id
      );
      if (tabelaPrecoProduto) return tabelaPrecoProduto.precoTabela;
      return preco;
    }
    if (tabelaPrecoCliente.aplicarDescontoEm === "Percentual") {
      return preco - preco * (tabelaPrecoCliente.descontoTabela / 100);
    }
    return preco - tabelaPrecoCliente.descontoTabela;
  }
  return preco;
}
