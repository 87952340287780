import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CurrencyInput,
  Dialog,
  DialogContent,
  Grid,
} from "../../../../../components";
import {
  formatProdutosTotalizadores,
  totalVenda,
  unformatPrice
} from "../../../../../utils";

const AcrescimoDialog = ({
  open,
  setOpen,
  venda,
  listaProdutosVenda,
  setListaProdutosVenda,
  setListaPagamentosVenda,
}) => {
  const [acrescimo, setAcrescimo] = useState(null);

  useEffect(() => {
    if (open) {
      setAcrescimo(venda?.acrescimo);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function handleInputAcrescimo(e) {
    e.persist();
    setAcrescimo(unformatPrice(e.target.value) || 0);
  }

  const handleSubmitAcrescimo = () => {
    const identificador = "acrescimo";
    const totalNota = totalVenda(venda, listaProdutosVenda, identificador);
    const listItensAcrescimo = formatProdutosTotalizadores(
      identificador,
      listaProdutosVenda,
      acrescimo,
      totalNota
    );
    setListaProdutosVenda(listItensAcrescimo);
    setListaPagamentosVenda([]);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setAcrescimo(null);
    setOpen(false);
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      PaperComponent="div"
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Card className="p-3">
          <CardHeader title="Acréscimo" />
          <CardContent className="p-3">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CurrencyInput
                  id="acrescimo"
                  name="acrescimo"
                  variant="outlined"
                  margin="none"
                  value={acrescimo}
                  onBlur={handleInputAcrescimo}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmitAcrescimo}
            >
              <i
                className="ph-fill ph-check-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              />
              Confirmar
            </Button>
            <Button
              color="inherit"
              variant="contained"
              onClick={handleCloseDialog}
            >
              <i
                className="ph-fill ph-x-circle"
                style={{ fontSize: 22, marginRight: 10 }}
              />
              Fechar
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default AcrescimoDialog;
