let timeout = null;

const timeoutBuscaAutocomplete = (buscar) => {
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    return buscar;
  }, 2000);
};

export default timeoutBuscaAutocomplete;
