import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "../../components";

const BotaoImpressaoWeb = ({ LayoutImpressao }) => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
        @page {
            size: auto;
            margin: 8mm;
        }
    `,
  });

  return (
    <div className="d-none">
      <div ref={componentRef}>{LayoutImpressao}</div>
      <Button
        id="imprimir-nfce"
        onClick={handlePrint}
        size="small"
        sx={{ borderRadius: 50 }}
      />
    </div>
  );
};

export default BotaoImpressaoWeb;
